/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './theme/variables.scss';

/* VIME (ANGULAR) */
//@import "~@vime/core/themes/default.css";
//@import "~@vime/core/themes/light.css";

/* PIA-3 Library scss-files */

/* PIA-3 Animation framework */
@import '~animate.css/animate.css';

/* PIA-3 VARIABLES */
$pia-shadow-blur: 12px;
$pia-shadow-transparency: 0.4;
$pia-shadow-shift-down: 2px;
$pia-shadow-shift-right: 1px;
$pia-shadow-spread: 0px;

$pia-shadow-color: rgba(0, 0, 0, $pia-shadow-transparency);

// /* PIA-3 MIXINS */
@mixin pia-shadow {
    -webkit-box-shadow: $pia-shadow-shift-right $pia-shadow-shift-down $pia-shadow-blur $pia-shadow-spread $pia-shadow-color;
    box-shadow: $pia-shadow-shift-right $pia-shadow-shift-down $pia-shadow-blur $pia-shadow-spread $pia-shadow-color;
}

/* PIA-3 STYLES */
ion-icon {
    pointer-events: none;
}

ion-content.content-center-screen {
    --background: var(--ion-color-primary);

    ion-grid {
        height: 100%;

        ion-row {
            height: 100%;

            ion-col {
                ion-card {
                    max-width: 480px;
                    margin: 0 auto;
                }
            }
        }
    }
}

/* Default Card */
ion-card {
    @include pia-shadow();
}

ion-card.pia3-list-item {
    width: 300px;
    float: left;
}

$region-label-height: 24px;
$region-audio-padding: 12px;
$region-handle-width: 10px;
$region-bg-transparancy: 0.3;
$region-bg-transparancy-strong: 0.6;

region.wavesurfer-region {
    border-top: $region-handle-width solid var(--ion-color-primary);
    border-bottom: $region-handle-width solid var(--ion-color-primary);
    background-color: rgba(0, 0, 0, 0.2) !important;

    &.selected {
        border-top: 10px solid var(--ion-color-tertiary) !important;
        border-bottom: 10px solid var(--ion-color-tertiary) !important;
    }

    handle.wavesurfer-handle {
        width: $region-handle-width !important;
        min-width: $region-handle-width !important;
        max-width: $region-handle-width !important;
        background-color: rgba(0, 0, 0, 0) !important;
        cursor: e-resize !important;

        &:hover {
            background-color: var(--ion-color-tertiary) !important;
        }

        &.wavesurfer-handle-start {
        }

        &.wavesurfer-handle-end {
        }
    }
}

/* PIA3 TRANSCRIPT(S) AND COMPONENTS =============================================================== */

ion-item.item-has-focus,
ion-item.ion-focused {
    border-bottom: 13px !important;
    -webkit-box-shadow: 1px 1px 13px 2px #616161;
    box-shadow: 1px 1px 13px 2px #616161;
    z-index: 9999;
    background-color: white;
}

/* PIA3-LANGUAGE-LABEL */
.pia-timelabel {
    font-size: 10px;
    color: grey;
}

/* PIA3-TRANSCRIPT-EDITOR-TOOL */
.pia-transcript-editor {
    :focus {
        border: 10px solid red;
    }

    ce-block {
        border: 2px solid transparent;

        &.ce-block--focused {
            border-color: var(--ion-color-primary);
        }
    }

    /* PIA3-TRANSCRIPT-EDITOR-TOOL-TAGS */

    //global language-tool styling
    $language-tool-padding: 6px;

    .language-tool {
        border: 3px solid;
        &::before {
            padding: 0 $language-tool-padding;
            color: white;
            font-weight: bold;
            border-right: $language-tool-padding solid white;
        }
        &::after {
            color: white;
            font-weight: bold;
            content: 'X';
            padding: 0 $language-tool-padding;
            border-left: $language-tool-padding solid white;
        }
    }

    //Styling per language
    .language-nl {
        color: red;
        &::before {
            content: 'NL: ';
            background-color: red;
        }
        &::after {
            background-color: red;
        }
    }
    language-fy {
        color: blue;
        &::before {
            content: 'FY: ';
            background-color: blue;
        }
        &::after {
            background-color: blue;
        }
    }
    language-en {
        color: green;
        &::before {
            content: 'FY: ';
            background-color: blue;
        }
        &::after {
            background-color: blue;
        }
    }
}
/* END PIA3 TRANSCRIPT(S) =============================================================== */

/* PIA3-VIDEO-PLAYER =============================================================== */

//Ngx-Videogural
@import url('~@videogular/ngx-videogular/fonts/videogular.css');

$iconButtonFontsize: 48px;

//Videoplayer
// @font-face {
//   font-family: videogular;
//   src: url(videogular.eot?hj1wei);
//   src: url(videogular.eot?hj1wei#iefix) format("embedded-opentype"),
//     url(videogular.ttf?hj1wei) format("truetype"),
//     url(videogular.woff?hj1wei) format("woff"),
//     url(videogular.svg?hj1wei#videogular) format("svg");
//   font-weight: 400;
//   font-style: normal;
// }

// vg-track-selector,
// vg-time-display,
// vg-playback-button {
//   font-size: 32px;
// }

$vgControlbarHeight: 100px;

vg-player.pia3-video-player {
    //Scrubbar on top
    vg-scrub-bar {
        transition: 1s;
        height: 10px;
        bottom: $vgControlbarHeight;
        align-items: flex-start;

        .scrubBar {
            align-items: flex-start;

            vg-scrub-bar-current-time {
                height: 10px;
            }
            vg-scrub-bar-buffering-time {
                height: 10px;
            }
        }
    }

    //Video
    video {
        cursor: pointer;
    }

    //Controlbar
    vg-controls {
        //border: 1px solid red;
        height: $vgControlbarHeight;

        &.topbar {
            top: 0;
            bottom: auto;
            transition: top 1s;
            &.hide {
                top: -$vgControlbarHeight;
            }

            ion-toolbar {
                width: 100%;
                --background: transparent;

                ion-buttons {
                    ion-button {
                        --background: transparent;
                        font-size: $iconButtonFontsize !important;
                        ion-icon {
                            font-size: $iconButtonFontsize !important;
                        }
                    }
                    &.left {
                    }
                    &.right {
                    }
                }

                ion-title {
                    width: auto;
                    text-align: center;
                    color: white;
                    font-weight: bold;
                }
            }
        }
        &.bottombar {
            top: auto;
            bottom: 0;
            transition: bottom 1s;

            ion-button {
                --background: transparent;
            }

            &.hide {
                bottom: -$vgControlbarHeight;
            }
        }

        //All direct siblings
        & > * {
            height: 100%;
            width: auto;
            margin-left: calc($vgControlbarHeight / 10);
            margin-right: calc($vgControlbarHeight / 10);
            line-height: $vgControlbarHeight;
            font-size: 32px;

            &[class*=' vg-icon-'],
            &[class^='vg-icon-'] {
                font-family: videogular !important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                font-size: 255px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        [class*=' vg-icon-'],
        [class^='vg-icon-'] {
            font-family: videogular !important;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            //border: 1px solid red;
            font-size: 42px;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            &.icon {
                font-size: 48px !important;
            }
        }

        //Control specific overrides
        //==========================================================

        vg-volume {
            width: $vgControlbarHeight;
        }
    }
}

.vg-icon-closed_caption:before {
    content: '\e006';
}
.vg-icon-pause:before {
    content: '\e018';
}
.vg-icon-play_arrow:before {
    content: '\e01b';
}
.vg-icon-repeat:before {
    content: '\e023';
}
.vg-icon-replay:before {
    content: '\e025';
}
.vg-icon-skip_next:before {
    content: '\e027';
}
.vg-icon-skip_previous:before {
    content: '\e028';
}
.vg-icon-stop:before {
    content: '\e02a';
}
.vg-icon-volume_down:before {
    content: '\e030';
}
.vg-icon-volume_mute:before {
    content: '\e031';
}
.vg-icon-volume_off:before {
    content: '\e032';
}
.vg-icon-volume_up:before {
    content: '\e033';
}
.vg-icon-hd:before {
    content: '\e035';
}
.vg-icon-forward_10:before {
    content: '\e038';
}
.vg-icon-forward_30:before {
    content: '\e039';
}
.vg-icon-replay_10:before {
    content: '\e03b';
}
.vg-icon-replay_30:before {
    content: '\e03c';
}
.vg-icon-fullscreen:before {
    content: '\e20c';
}
.vg-icon-fullscreen_exit:before {
    content: '\e20d';
}
